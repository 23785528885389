<template>
  <div>
    <!-- 历史作品 -->
    <div class="header-box" />
    <!-- 历史作品页 -->
    <!-- banner -->
    <div
      v-if="screenWidth>1200"
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b', backgroundSize: '100%, 100%'}"
    >
      <!-- {{ exhibitionName }}北京市大学生工业设计大赛 -->
    </div>
    <div
      v-else
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b', backgroundSize: '100%, 100%'}"
    >
      <!-- {{ exhibitionName }}北京市大学生工业设计大赛 -->
    </div>

    <!-- 内容 -->
    <div class="container">
      <div class="content">
        <!--        <div v-for="i in 3" :key="i" class="prize-body">-->
        <!--          &lt;!&ndash; 奖项名称 &ndash;&gt;-->
        <!--          <div class="prize-title">一等奖</div>-->

        <!--          &lt;!&ndash; 作品展示 &ndash;&gt;-->
        <!--          <div class="work-body">-->
        <!--            <el-row v-for="row in 2" :key="row" :gutter="30">-->
        <!--              <el-col v-for="col in 4" :key="col" :xs="24" :sm="24" :md="24" :lg="6">-->
        <!--                <div class="work-img">-->
        <!--                  <el-image style="vertical-align: bottom" :src="testPic" :preview-src-list="[testPic]"></el-image>-->
        <!--                </div>-->

        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </div>-->

        <!--          &lt;!&ndash; 分页栏 &ndash;&gt;-->
        <!--          <div class="page">-->
        <!--            <pagination v-show="total>0" :background="true" :layout="layout" :total="total" :page.sync="listQuery.page"-->
        <!--                        :limit.sync="listQuery.limit" @pagination="fetchWorks"/>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="award-works">
          <div v-for="(item, key) in award_work" :id="key" :key="key" class="">
            <!-- 奖项名称 -->

            <div class="prize-title">{{ item['prizeName'] }}</div>
            <!-- <div class="add-btn" @click="onBeginAwardWork(item['prizeName'])">
              <i class="el-icon-circle-plus" />
            </div> -->
            <!-- 奖项列表 -->
            <!-- <div>{{ item['worklist'] }}</div> -->
            <!--            <div class="work-display">-->
            <!--              <Workdisplay :list="item['worklist']" :is-checked="true" @unshowId="addUnshowWork(arguments)" />-->
            <!--            </div>-->
            <div class="work-body">
              <el-row v-for="(row, r_id) in 2" :key="row" :gutter="30">
                <el-col v-for="(col, c_id) in 4" :key="col" :xs="24" :sm="24" :md="24" :lg="6">
                  <div v-if="(r_id*4+c_id)<item.worklist.length" class="work-img">
                    <el-image
                      style="vertical-align: bottom"
                      :src="previewTiny(item.worklist[r_id*4+c_id].posterSrc)"
                      :preview-src-list="[previewTiny(item.worklist[r_id*4+c_id].posterSrc)]"
                    />
                  </div>

                </el-col>
              </el-row>
            </div>
            <div class="page">
              <pagination
                v-show="item['total']>0"
                :background="true"
                :total="item['total']"
                :page.sync="item['listQuery'].page"
                :limit.sync="item['listQuery'].limit"
                @pagination="fetchAwardItem(key)"
              />
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
// import { fetchResource } from '@/api/homepage'
import { retrieveExhibitionWork } from '@/api/homepage'
import { pr } from '@/utils/preview'

export default {
  components: { Pagination },
  data() {
    return {
      // 界面大小
      screenWidth: '',
      screenHeight: '',
      banner: require('@/assets/worksBanner.jpeg'),
      exhibitionId: '',
      exhibitionName: '',
      testPic: 'https://bus-acc.cuidc.net/pro/user/race-card/race_card_com_21715_1595038848.jpg?x-oss-process=image/resize,w_1920',

      // 图片baseurl
      pictureSrc: `${process.env.VUE_APP_PIC_URL}`,
      // 分页
      layout: 'total, prev, pager, next, jumper',
      // listLoading: true,
      total: 4,
      listQuery: {
        page: 1,
        limit: 4
      },
      award_work: [{
        prizeName: '特等奖',
        worklist: [{ id: 1, posterSrc: '', isPublicize: true }],
        listLoading: true, total: 1,
        listQuery: { page: 1, limit: 4 }
      }],
      originWorks: [{ prizeName: '', works: [{ id: 0, posterSrc: '', isPublicize: true }] }],
      mutex: false
    }
  },
  watch: {
    $route(to, from) {
      // console.log('toooopath', to.path)
      // console.log('frommmm', from.path)
      if (to.path !== from.path) {
        const realId = parseInt(this.$route.params.id.split(':')[0])
        const realName = this.$route.params.id.split(':')[1]
        this.$route.params.id = realId
        console.log('realName', realName)
        this.exhibitionName = realName
        this.init(this.$route.params.id)
      }
    }
  },
  created() {
    this.award_work = []
    this.exhibitionId = this.$route.params.id
  },
  mounted() {
    // 实时监听窗口大小的变化
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
      })()
    }
    this.award_work = []
    const realId = parseInt(this.$route.params.id.split(':')[0])
    const realName = this.$route.params.id.split(':')[1]
    this.$route.params.id = realId
    this.exhibitionId = this.$route.params.id
    console.log('realName', realName)
    this.exhibitionName = realName
    this.init(this.exhibitionId)
  },
  methods: {
    async init(id) {
      this.originWorks = []
      this.award_work = []
      await retrieveExhibitionWork(id, false).then(Response => {
        this.originWorks = Response.data
        this.originWorks.forEach((element, index) => {
          this.award_work.push({ prizeName: element.prizeName, worklist: [], total: element.works.length, listLoading: true, listQuery: { limit: 8, page: 1 }})
          this.fetchAwardItem(index)
        })
        console.log(this.award_work)
      })
    },
    previewTiny: (url) => {
      return pr.previewTiny(url)
    },
    fetchAwardItem(key) {
      const page = this.award_work[key].listQuery.page
      const limit = this.award_work[key].listQuery.limit
      this.award_work[key].worklist = this.originWorks[key].works.slice(page === 1 ? 0 : (page - 1) * limit, page * limit)
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.banner {
  width: 100%;
  height: 550px;
  background-color: var(--background-color);
  color: #fff;
  line-height: 550px;
  text-align: center;
  font-size: 50px;
}

@media screen and (max-width: 1200px) {
  .banner {
    height: 250px;
    background-color: var(--background-color);
    line-height: 250px;
    text-align: center;
    font-size: 16px;
  }
}

.banner-name {
  color: #fff;
  height: 550px;
  line-height: 550px;
  text-align: center;
  font-size: 50px;
  /* font-weight: 800; */
}

.container {
  /* width: 100%; */
  text-align: center;
  padding-top: 30px;
  min-height: 300px;
  /* border: 1px solid palegreen; */
  background-color: var(--background-light-color);
}

@media screen and (max-width: 1200px) {
  .container {
    padding-top: 20px;
    /* background-color: var(--background-color); */
  }
}

.content {
  /* display: table-cell; */
  display: inline-block;
  margin: auto;
  margin-top: 10px;
  width: 90vw;
  /* background-color: #fff; */
  /* border-radius: 10px; */
  padding-bottom: 70px;
  margin-bottom: 50px;
  text-align: left;

  /* border: 1px solid red; */
}

@media screen and (max-width: 1200px) {
  .content {
    width: 330px;
  }
}

.prize-body {
  margin-bottom: 40px;
  /* border: 1px solid red; */
}

.prize-title {
  padding-left: 8px;
  text-align: left;
  /* font-size: 14px; */
  font-weight: 800;
  border-left: 5px solid var(--background-color);
}

.work-body {
  margin-top: 30px;
}

.work-img {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
}

@media screen and (max-width: 1200px) {
  .work-img {
    width: 330px;
    margin: auto;
    margin-bottom: 20px;
  }
}

.page /deep/ .pagination-container {
  text-align: right;
  padding-top: 15px;
}
</style>
